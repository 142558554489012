import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Useful Links",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Online Logbook",
    url: "https://gm5g.cloudlog.co.uk/index.php/visitor/gm5g",
    description:
      "Check and see if your in our logbook.",
    color: "#E95800",
  }
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Grampian Hilltoppers Contest Group</title>
      <h1 style={headingStyles}>
        Grampian Hilltoppers ⛰️
        <br />
        <span style={headingAccentStyles}>MM0TGH, GM5G</span>
      </h1>
      <p style={paragraphStyles}>
        Making amateur radio 📻 fun in the North East of Scotland 🏴󠁧󠁢󠁳󠁣󠁴󠁿
      </p>
      <ul style={listStyles}>
        <li style={docLinkStyle}>
            {docLink.text}
        </li>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}`}
              >
                {link.text}
              </a>
              {link.badge && (
                <span style={badgeStyle} aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
      
      <h2 style={headingStyles}>Past Special Event Callsigns</h2>
      <p style={paragraphStyles}><a href="https://www.qrz.com/db/GB2KDR" target="_blank">GB2KDR</a>, <a href="https://www.qrz.com/db/GB1TMH" target="_blank">GB2TMH</a></p>
    </main>
  )
}

export default IndexPage
